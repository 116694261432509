import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const news = graphql`
  query ContactQuery {
   allContentfulContact {
    edges {
      node {
        title,
        textIntro {
          json
          },
        
        tarifs {
          json
          },
        
        infoContact {
          json
          }
        }
      }
    }
  }
`

const ContactPage = ({ data }) => (
  <Layout>
    <SEO title="Contact" />
    <h1>Contact</h1>

    <div>{documentToReactComponents(data.allContentfulContact.edges[0].node.textIntro.json)}</div>
    <div>{documentToReactComponents(data.allContentfulContact.edges[0].node.tarifs.json)}</div>
    <div>{documentToReactComponents(data.allContentfulContact.edges[0].node.infoContact.json)}</div>

    <Link to="https://www.facebook.com/Kendo-Ia%C3%AFdo-Club-Niortais-140693822681929/?view_public_for=140693822681929">Lien facebook</Link>

  </Layout>
)

export default ContactPage
